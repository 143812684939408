.privacy {
    text-align: start;
    font-family: sans-serif;

    p {
        margin-bottom: 1rem !important;
    }

    h2,
    h4,
    h3 {
        font-weight: 900;
        margin-bottom: 1.5rem !important;

    }
}