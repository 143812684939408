.pagination {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  min-height: 80px;
  padding: 0 !important;
  margin: 0 !important;

  .disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .page-link {
    border-radius: 6px !important;
    border: none;
    font-size: 12px;
    font-weight: 700;
    background: var(--outlet-bg);
    color: var(--light-text);
    padding: 0.5rem 1rem;
    text-wrap: nowrap;
    margin-inline: 0.5rem;
  }

  .pagination_numbers {
    display: flex;
    align-items: center;
    gap: 0.3rem;

    .page-link {
      color: var(--black);
    }
  }

  .active>.page-link,
  .page-link.active {
    color: var(--primary);
    background-color: var(--list-hover);
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: var(--primary);
      display: block;
      position: absolute;
      bottom: -20px;
      left: 1px;
    }
  }
}